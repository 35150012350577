var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal-mask h-100 mh-100vh",
        class: { not_ie: !_vm.$isIE },
      },
      [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c(
            "div",
            { staticClass: "modal-container" },
            [
              _c(
                "card",
                {
                  staticClass: "border-0",
                  attrs: {
                    type: "secondary",
                    shadow: "",
                    "header-classes": "bg-white pb-4 card",
                    "body-classes": "px-4 py-4",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _vm._t("title", function () {
                        return [_vm._v("default header")]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _vm._t("content", function () {
                        return [_vm._v("default body")]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _vm._t("footer", function () {
                        return [
                          _vm._t("button1"),
                          _vm._v(" "),
                          _vm._t("button2"),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }