
import { Vue, Component } from "nuxt-property-decorator";
@Component({
  components: {
    BaseDropdown: () => import("~/argon-components/BaseDropdown.vue"),
  }
})
export default class LocaleSwitcher extends Vue {
  data() {
    return {
      selected: this.$i18n.locale
    }
  }
  switchLocale(lang){
    this.$axios.post(`api/v1/user/setting/language`, {
        "language_code": lang
      }).then(()=>{
        this.$auth.fetchUser();
        this.$router.push((this as any).switchLocalePath(lang));
      })
  }
}
