var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "err-netwwork d-flex" }, [
    _c("div", { staticClass: "block-left flex-center" }, [
      _c(
        "svg",
        {
          attrs: {
            width: "42",
            height: "32",
            viewBox: "0 0 42 32",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M19.864 1.1302L19.866 1.12719C19.9941 0.935283 20.1689 0.777094 20.3752 0.667375C20.5816 0.55764 20.8127 0.5 21.0478 0.5C21.283 0.5 21.5141 0.55764 21.7205 0.667375C21.9268 0.777093 22.1016 0.935284 22.2297 1.12719L22.2311 1.12923L41.2686 29.3494C41.2689 29.3498 41.2691 29.3501 41.2693 29.3504C41.4082 29.559 41.4871 29.8 41.4986 30.0482C41.51 30.2969 41.4533 30.5442 41.3339 30.7643C41.2145 30.9845 41.0366 31.1695 40.8183 31.2994C40.6003 31.429 40.3503 31.4985 40.0949 31.5L1.92273 31.5L1.92034 31.5C1.66332 31.5012 1.41117 31.4336 1.19082 31.3047C0.970506 31.1759 0.790565 30.991 0.669461 30.7704L0.231947 31.0106L0.669461 30.7704C0.548389 30.5499 0.490432 30.3015 0.501287 30.0517C0.512119 29.8024 0.591091 29.5603 0.730425 29.3508C0.730738 29.3504 0.731052 29.3499 0.731365 29.3494L19.864 1.1302Z",
              stroke: "$second-background;",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M22.9517 11.2958C22.9517 10.2525 22.095 9.40674 21.0382 9.40674C19.9814 9.40674 19.1248 10.2525 19.1248 11.2958V18.852C19.1248 19.8953 19.9814 20.741 21.0382 20.741C22.095 20.741 22.9517 19.8953 22.9517 18.852V11.2958Z",
              fill: "white",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M21.0382 26.4075C22.095 26.4075 22.9517 25.5617 22.9517 24.5184C22.9517 23.4751 22.095 22.6294 21.0382 22.6294C19.9814 22.6294 19.1248 23.4751 19.1248 24.5184C19.1248 25.5617 19.9814 26.4075 21.0382 26.4075Z",
              fill: "white",
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "block-right flex-1 d-flex align-items-center justify-content-between",
      },
      [
        _c("div", { staticClass: "flex-1 __label" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("error.ERROR_ERR_NETWORK_DISCONNECTED"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "refresh",
            on: {
              click: function ($event) {
                return _vm.$emit("refeshPage")
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "24",
                  height: "24",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M20.3 13.4301C20.048 13.3515 19.7752 13.3758 19.5411 13.4975C19.3069 13.6193 19.1304 13.8287 19.05 14.0801C18.5776 15.5245 17.6566 16.7806 16.421 17.6655C15.1854 18.5503 13.6997 19.0179 12.18 19.0001C10.3003 19.0214 8.48894 18.2965 7.14297 16.9843C5.79701 15.6721 5.02635 13.8796 5 12.0001C5.02635 10.1205 5.79701 8.32804 7.14297 7.01582C8.48894 5.7036 10.3003 4.97869 12.18 5.00006C13.8776 4.99596 15.5229 5.58686 16.83 6.67006L14.66 6.31006C14.5299 6.28866 14.3969 6.29318 14.2686 6.32336C14.1403 6.35354 14.0193 6.40879 13.9124 6.48592C13.8055 6.56306 13.715 6.66056 13.6459 6.77282C13.5768 6.88508 13.5307 7.00989 13.51 7.14006C13.4886 7.27011 13.4931 7.40311 13.5233 7.53141C13.5535 7.65971 13.6087 7.78078 13.6859 7.88765C13.763 7.99452 13.8605 8.08509 13.9728 8.15415C14.085 8.22321 14.2098 8.2694 14.34 8.29006L18.58 8.99006H18.75C18.866 8.98992 18.981 8.96962 19.09 8.93006C19.1266 8.91615 19.1605 8.89584 19.19 8.87006C19.2617 8.8434 19.3291 8.80633 19.39 8.76006L19.48 8.65006C19.48 8.60006 19.57 8.56006 19.61 8.50006C19.65 8.44006 19.61 8.40006 19.66 8.36006C19.6876 8.30182 19.711 8.24166 19.73 8.18006L20.48 4.18006C20.505 4.04874 20.5038 3.91379 20.4766 3.78291C20.4494 3.65204 20.3967 3.5278 20.3215 3.4173C20.2462 3.3068 20.15 3.21219 20.0382 3.13889C19.9264 3.06558 19.8013 3.01501 19.67 2.99006C19.5387 2.96511 19.4037 2.96627 19.2729 2.99347C19.142 3.02067 19.0177 3.07339 18.9072 3.1486C18.6841 3.30051 18.5304 3.53484 18.48 3.80006L18.21 5.25006C16.536 3.7993 14.3952 3.00049 12.18 3.00006C9.76993 2.97873 7.44984 3.91435 5.7288 5.60164C4.00777 7.28892 3.02639 9.59004 3 12.0001C3.02639 14.4101 4.00777 16.7112 5.7288 18.3985C7.44984 20.0858 9.76993 21.0214 12.18 21.0001C14.1331 21.0301 16.0443 20.4322 17.6321 19.2944C19.2199 18.1567 20.4005 16.5392 21 14.6801C21.0379 14.552 21.0498 14.4177 21.035 14.285C21.0202 14.1523 20.9789 14.0239 20.9137 13.9074C20.8485 13.7909 20.7605 13.6886 20.6551 13.6066C20.5497 13.5247 20.429 13.4646 20.3 13.4301Z",
                    fill: "#525252",
                  },
                }),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }