var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-dropdown", {
    scopedSlots: _vm._u([
      {
        key: "toggleButton",
        fn: function ({ toggleDropdown }) {
          return _c(
            "a",
            {
              staticClass: "notification",
              attrs: { type: "secondary" },
              on: { click: toggleDropdown },
            },
            [
              _vm.$i18n.locale === "ja"
                ? _c("div", [
                    _c("p", { staticClass: "language" }, [
                      _vm._v(_vm._s(_vm.$t("common.japanese"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$i18n.locale === "vi"
                ? _c("div", [
                    _c("p", { staticClass: "language" }, [
                      _vm._v(_vm._s(_vm.$t("common.vietnamese"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$i18n.locale === "en"
                ? _c("div", [
                    _c("p", { staticClass: "language" }, [
                      _vm._v(_vm._s(_vm.$t("common.english"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.$i18n.locale === "pt"
                ? _c("div", [
                    _c("p", { staticClass: "language" }, [
                      _vm._v(_vm._s(_vm.$t("common.portuguese"))),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        },
      },
      {
        key: "dropdown",
        fn: function ({ toggleDropdown }) {
          return _c(
            "div",
            {
              staticClass: "locale-switcher",
              on: { click: toggleDropdown, toggleDropdown: toggleDropdown },
            },
            [
              _c("div", { staticClass: "d-flex choose-language" }, [
                _c(
                  "a",
                  {
                    staticClass: "language-button",
                    on: {
                      click: function ($event) {
                        return _vm.switchLocale("ja")
                      },
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$i18n.locale,
                          expression: "$i18n.locale",
                        },
                      ],
                      attrs: { type: "radio", value: "ja" },
                      domProps: { checked: _vm._q(_vm.$i18n.locale, "ja") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.$i18n, "locale", "ja")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.$t("common.japanese") + " (JP)")),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex choose-language" }, [
                _c(
                  "a",
                  {
                    staticClass: "language-button",
                    on: {
                      click: function ($event) {
                        return _vm.switchLocale("vi")
                      },
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$i18n.locale,
                          expression: "$i18n.locale",
                        },
                      ],
                      attrs: { type: "radio", value: "vi" },
                      domProps: { checked: _vm._q(_vm.$i18n.locale, "vi") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.$i18n, "locale", "vi")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.$t("common.vietnamese") + " (VI)")),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex choose-language" }, [
                _c(
                  "a",
                  {
                    staticClass: "language-button",
                    on: {
                      click: function ($event) {
                        return _vm.switchLocale("en")
                      },
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$i18n.locale,
                          expression: "$i18n.locale",
                        },
                      ],
                      attrs: { type: "radio", value: "en" },
                      domProps: { checked: _vm._q(_vm.$i18n.locale, "en") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.$i18n, "locale", "en")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.$t("common.english") + " (EN)")),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "language-button",
                    on: {
                      click: function ($event) {
                        return _vm.switchLocale("pt")
                      },
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$i18n.locale,
                          expression: "$i18n.locale",
                        },
                      ],
                      attrs: { type: "radio", value: "en" },
                      domProps: { checked: _vm._q(_vm.$i18n.locale, "en") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.$i18n, "locale", "en")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.$t("common.portuguese") + " (PT)")),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }